<template>
  <v-app id="app">
   <AppLayout> 
     <v-container fluid>
      <transition name="fade" mode="out-in">
        <router-view/> 
      </transition>
     </v-container>
   </AppLayout> 
   <confirm ref="confirm"></confirm>
  </v-app>
</template>

<script>
//AppLayout - defined in main.js
import  {zmlConfig } from '@/api/constants';
import { getters } from "@/api/store";
import confirm from '@/api/DialogConfirm';
import { zmlFetch } from '@/api/zmlFetch';

//import { werner } from './plugins/vuetify';
export default {
  name: 'MainApp',
  components: {  confirm  },
  data: () => ({
    appVar:'This is appVar',
    getZml: getters.getState({ object: "gZml" }),
    demo:0,
  }),
  methods: { 
    async loadLocalData() {
      if (this.getZml.content != '') return;
      let ts = {};
      ts.task = 'getData';
      zmlConfig.cl(ts);
      await zmlFetch(ts, this.processData);
    },
    processData(response) {
      let wasResponse = response['was'];
      let packageResponse = response['package'];
      this.getZml.packageGroup = [];
      for (let i=0; i < packageResponse.length; i++) {
        if (packageResponse[i].sortorder==0) continue;
        this.getZml.package.push(packageResponse[i]);
        this.addUniq(this.getZml.packageGroup,packageResponse[i].groupname);
      }
      for (let i=0; i < wasResponse.length; i++) {
          if (wasResponse[i]['type'] == 2) {
             this.getZml.content.push(wasResponse[i]);
          }
          if (wasResponse[i]['type'] == 3) {
             this.getZml.expand.push(wasResponse[i]);
          }
          if (wasResponse[i]['type'] == 1) {
             const arrayItem = wasResponse[i]['wasid']; 
             this.getZml.screen[ arrayItem ] = wasResponse[i]['description'];
             this.getZml.screen[ wasResponse[i]['title'] ]  = wasResponse[i]['description'];
          }
      }
      //zmlConfig.cl('APPscreen&content Array = ', this.getZml.screen,this.getZml.content);
    },
    addUniq(myArray,myVal) {
        const position = myArray.indexOf(myVal);
        if (!~position) {
           myArray.push(myVal);
        }
      },
   },
  mounted: function () {
      this.loadLocalData();    
      zmlConfig.cl('APPmount : ', this.$options.name , 'c=',this.$children.length, process.env);
      this.$root.$confirm = this.$refs.confirm.open;
      //werner('red')
      this.$vuetify.theme.themes.light.primary = this.getZml.primaryColor
  }
};
</script>


<style scoped>
#v-app {
    background: rgba(0,0,0,0);
}

.info {
  background-color: yellowgreen;
  text-align: center;
}
</style>