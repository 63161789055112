import { zmlConfig } from '@/api/constants';

/*-----------------------------
zmlFetch - to make my code more readable.
You can pass only first param if there are no callback
self can be used with let self=this, and then you pass all you data here.
will make this function a bit bloated.
-----------------------------*/
// eslint-disable-next-line
function zmlFetch(task,callback,errcallback) {
        task.program = 'zmlShop';
        task.status = 'trying';
        let apiConfig = {method: 'POST',
                  headers: {'Accept': 'application/json'
                         , 'Content-Type': 'application/json;charset=UTF-8'},
                  body: JSON.stringify(task)};
        console.info('FETCH-------------- : ', task.task);
        fetch(zmlConfig.apiPath, apiConfig)
        .then(response => response.json())  
        .then(response => { 
           if (callback) callback(response,task);
           zmlConfig.cl('ZF: after fetch callback for ',task.task);
        })
        .catch((err)=>{
            zmlConfig.cl('ZF: Errorname : ',task.task , 'Error:' , err, task);
            if (errcallback) errcallback(err);
        });
    }

export {zmlFetch};    