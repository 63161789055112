<template>

<!-- https://betterprogramming.pub/changing-application-theme-dynamically-in-vuetify-js-c01d640699c4 -->
<div>
  <div class="water wave" v-if="displayKovsieWas">
   <v-img max-height="400px" 
          contain
          @click="displayKovsieWas=false" 
          src="/img/SipressLogoBlok.jpg" />

  </div>
  
   <v-row>
    <v-col xs-12 lg-7>
      <exp-dropdown />
    </v-col>
    <v-col xs-10 lg-5>
      <contact-details  @nameClick="displayKovsieWas = !displayKovsieWas" />
    </v-col>
   </v-row>
    <v-container fill-height pa-2 fluid class="primary lighten-1" style="font-family: 'Work Sans', sans-serif;">
       <v-card class="ma-2" v-for="item in content" :key="item.wasid">
        <v-card-title class="pa-2 heading">{{ item.title}}</v-card-title>
        <v-card-text v-html="item.description"> </v-card-text>
      </v-card>       
  </v-container>

  <whatsappbutton  />
</div>
</template>
<script>
import { zmlConfig } from '@/api/constants';
import { zmlLog } from '@/api/zmlLog';
import { getters } from "@/api/store";
import ContactDetails from "@/components/ContactDetails";
import ExpDropdown from "@/components/ExpDropdown";
import whatsappbutton from "@/components/whatsappbutton";
export default {
  name: "Laundry",
  components: { ContactDetails, ExpDropdown,whatsappbutton },
  data: () => ({
     getZml: getters.getState({ object: "gZml" }),
     content: [],
     displayKovsieWas: true,
  }),
  methods: {
  },
  mounted: function () {
    zmlConfig.cl('LMmount : ', this.$options.name , 'c=',this.$children.length);    
    zmlLog({task:"dolog",user:"None", pagename:"MainPage", logdata: "Start of KovsieWas"+zmlConfig.projectId});
    this.content = this.getZml.content;
  }
}
</script>


<style scoped>

</style>