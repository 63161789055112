const PROJECT_ID = "onShaw12";
const MEASUREMENT_ID = "Z-WAS14";
const API_PATH = "https://sipress.co.za/api/was/was.php" ;
const HOME = "https://www.sipress.co.za/" ;
const DEFAULT_LAYOUT = "AppLayoutDefault";

export const zmlConfig = {
  apiPath : API_PATH, 
  localPath: HOME,  
  projectId: PROJECT_ID,
  measurementId: MEASUREMENT_ID,
  defaultLayout: DEFAULT_LAYOUT,
  // eslint-disable-next-line
  cl: function (...args) {  
    //console.log(...args); 
  }
}

export const promiseTimeout = function(ms, promise){
  // Create a promise that rejects in <ms> milliseconds
  let timeout = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      reject('Timed out in '+ ms + 'ms.')
    }, ms)
  })
  // Returns a race between our timeout and the passed in promise
  return Promise.race([
    promise,
    timeout
  ])
}


//add it to vue file like this.
//import { zmlConfig } from '@/api/constants.js'

//------------------------------------------
//Anyplace you want to use my console.log...
//------------------------------------------
//import { zmlConfig } from '@/api/constants';
//zmlConfig.cl('mount : ', this.$options.name , 'c=',this.$children.length);
