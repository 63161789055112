<template>
<div>
     <v-card>
      <v-card-title>
      <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Contact Details</v-toolbar-title>
    </v-toolbar>           
       </v-card-title>
       <v-card-text> 
           <v-list>
               <v-list-item @click="$emit('nameClick',1)">
               <v-avatar color="primary" >
                <v-icon dark>mdi-human-male</v-icon>
                <v-icon dark>mdi-human-female</v-icon>
                </v-avatar>
                <v-btn text > Michael en Zi-Mari Shaw</v-btn>
               </v-list-item>
               <br />
               <v-list-item>
                 <v-btn fab>
               <v-avatar color="primary" >
                <v-icon dark>mdi-phone</v-icon>
                </v-avatar></v-btn>
                <v-btn text href="tel:+27-72-826-0575"> 072 826 0575</v-btn> /
                <v-btn text href="tel:+27-82-561-8880" target="wazzup"> 082 561 8880</v-btn>
               </v-list-item>
               <br />
               <v-list-item>
               <v-avatar color="primary" >
                <v-icon dark>mdi-email</v-icon>
                </v-avatar>
                <v-btn text href=mailto:sipress.was@gmail.com>sipress.was@gmail.com</v-btn>
               </v-list-item>
               <br />
               <v-list-item>
               <v-avatar color="primary" >
                <v-icon dark>mdi-clock</v-icon>
                </v-avatar>
                <v-btn text> 07h30 - 17h00</v-btn>
               </v-list-item>

           </v-list>
       </v-card-text>
      <!--v-card-actions> <v-btn> OK </v-btn></v-card-actions-->
     </v-card>
</div>
</template>

<script>
import { zmlConfig } from '@/api/constants';
export default {
  name: "ContactDetails",
  data: () => ({
    }),   
    methods:{},
    mounted: function () { 
      zmlConfig.cl('CDmount : ', this.$options.name , 'c=',this.$children.length);
    }
}
</script>