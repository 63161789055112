<template>
  <div>
    <v-card>
      <v-card-title>
          <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Information</v-toolbar-title>
    </v-toolbar>
      </v-card-title>
      <v-card-text>
      <v-expansion-panels hover popout focusable tile
             class="ma-2 v" 
             :value="expsel"
             v-for="item in getZml.expand" 
             :key="item.wasid">
      <v-expansion-panel>
       <v-expansion-panel-header ripple> {{ item.title }} </v-expansion-panel-header>
         <v-expansion-panel-content v-html="item.description" color="primary" class="ma-2 pa-2 a">
         </v-expansion-panel-content>
      </v-expansion-panel>
     </v-expansion-panels>
      </v-card-text>
    </v-card>


    <v-card>
      <v-card-title>
       <v-toolbar flat color="primary" dark>
        <v-toolbar-title>
         Deals/Packages/Specials
        </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
       <v-tabs vertical>
        <v-tab v-for="item in getZml.packageGroup" :key="item.id">
         <v-btn block 
             :to="{name:'Packages', params:{packagename:item}}" 
             title="Packages and Prices"  
             class="ma-2">
          {{ item }}
         </v-btn>
        </v-tab>

    <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Click on one of these buttons to see the availiable package deals and specials we have at the moment.
            </p>
          </v-card-text>
         </v-card>
    </v-tab-item>
        </v-tabs>
        </v-card-text>
    </v-card>
  </div>     
</template>     

<script>
import { zmlConfig } from '@/api/constants';
import { getters } from "@/api/store";
export default {
  data: () => ({
     name: 'ExpDD',
     expsel:[],
     getZml: getters.getState({ object: "gZml" }),
   }),
  methods: {
      //we are waiting for parent to load the data into getZml.content...
      //so we add a timeout of 4 secs, and show the hardcoded stuff so long.
    showData() {
     const p1 = new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
         setTimeout(() => { resolve(this.loadIt()); } , 5000);
     });
     p1.then(answer => { 
         console.log(answer);  //we expect to see "Done with load"
     });

    },
    loadIt() {
        //console.log('LOADIT',this.getZml.expand.length);
        if (this.getZml.expand.length == 0) {
           alert('our expand list is zero!');
        }
        return('Done with load', this.getZml.expand.length, this.getZml.packageGroup.length);
    }
  },
  mounted: function () {
    zmlConfig.cl('EDmount : ', this.$options.name , 'c=',this.$children.length);    
    this.showData();
  }
}
</script>


<style scoped>
.v >>> .liber { font-family: "Liberation Serif", serif;     }
.v >>> .xli { font-family: "Imprint MT Shadow, serif";    font-size: 15pt;     }
.v >>> .head { font-size: 28pt;text-align: center;}
.v >>> .big { font-size: 20pt;}
.v >>> .medium { font-size: 14pt;}
.v >>> .small { font-size: 12pt;}      
.v >>> .whitetext {
  color: #fff;
}
.v >>> .redtext {    color: #a5300f;}
.v >>> .cent {  text-align:center;}
.cover {
  border-radius: 15px;
  margin-left:10%;
  margin-right:10%;
  margin-bottom:2px;
  margin-top:2px;
  padding:4px;
  text-transform: uppercase;
  text-align:center;
  line-height: 1.2;
  color: #fff;
  mix-blend-mode: multiply;
}

hr.lyn {
  border: 2px solid white;
  border-radius: 2px;
}

</style>