<template>
 <div>
    <v-row justify="center" class="fill-height" xalign="stretch">
     <v-col>
       <v-card max-width=150          min-width=250
          min-height=150         max-height=350
          color=#F5F5F5          style="xoverflow: auto;">
      <v-card-title>
       <h3 class="headline wordbreak red--text text--accent-2 text-center"><!--{{ packageItem.groupname }}<br--> {{ packageItem.service }}
       </h3>
       </v-card-title>
       <v-hover v-slot:default="{ hover }">
         <v-card
          max-width=150          min-width=250
          min-height=150         max-height=350
          color=#F5F5F5          style="xoverflow: auto;"
          :elevation="hover ? 12 : 2"
          :class="{'on-hover': hover,'overwrite-hover': $vuetify.breakpoint.xsOnly}">
          <!--v-card-title>whatever</v-card-title-->
           <v-expand-transition>
              <div v-if="hover && packageItem.packagename!=''" 
                   class="d-flex justify-center transition-fast-in-fast-out darken-2 v-card--reveal " 
                   style="align-items: center;">
                   <v-icon>mdi-washing-machine</v-icon>
                Sipress Package : <i>{{ packageItem.packagename }}</i>        
              </div>
            </v-expand-transition>
            <div class="wordbreak ma-2 myitem text-center">
             <span>
               {{ packageItem.groupname }}
              
               <div v-if="packageItem.reps > 1"> 
                   <br>{{packageItem.period}}  :  R{{ packageItem.price}}
               </div>
                <br>
                <center> 
                 <span v-if="packageItem.reps > 1">
                 {{ packageItem.reps }} Washes
                 </span>
                 <template v-if="packageItem.weight > 0">
                 <span v-if="packageItem.reps > 1"> max</span>
                 <span v-else> min</span>  
                 {{ packageItem.weight }}KG
                 <br>
                 <br>
                 </template>
                </center>

                 <template v-if="packageItem.priceperkg > 1">
                  <center>
                   <small>{{ packageItem.description }}</small>
                  </center>
                 </template>
                 <template v-else>
                   <div v-html="packageItem.description" />
                 </template>
             </span>

             <div v-if="packageItem.reps > 1"> 
               ({{packageItem.reps}} x {{packageItem.weight}}) / {{packageItem.price}} =  
            
              {{ (packageItem.price / (packageItem.reps * packageItem.weight)).toFixed(2)}} R/KG 
             </div>
             <br>
             <div v-if="packageItem.priceperkg > 1">
              Price per KG = R{{packageItem.priceperkg}}
             </div>
             <br><br>
                 
            </div>
          </v-card>
         </v-hover>
       </v-card>
        </v-col>
        
      </v-row>  
  </div>
</template>

<script>
export default {
  name: "packageItem",
  props: { packageItem:Object },
  data: () => ({
    hallo:"world"
  }),
  filters: {
    formatNumber(value) {
       return value.tofixed(1); // displaying other groupings/separators is possible, look at the docs
    }
  },
  methods:{},
}
</script>

<style scoped>
.myitem {
    font-size: 14px;
    color: #222;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.wordbreak {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

</style>