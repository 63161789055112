import Vue from 'vue'
import VueRouter from 'vue-router';
import Was from '@/views/LaundryMain';
import Packages from '@/views/Packages';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Was',
    component: Was,
    meta: {
        layout: 'AppLayoutLaundry'
      }    
  },   
  {
    path: '/help',
    name: 'Help',
    component: () => import('@/views/Help.vue'),
    meta: {
        layout: 'AppLayoutBlue'
      }    
  },
  {
    path: '/editme',
    name: 'EditMe',
    component: () => import('@/views/Edit.vue'),
    meta: {
        layout: 'AppLayoutLaundry'
      }    
  },
  {
    path: '/packages/:packagename',
    name: 'Packages',
    props: {packagename:''} ,
    component: Packages,
    meta: {
        layout: 'AppLayoutLaundry'
      }    
  },  
]

const router = new VueRouter({
  mode: 'history',
  base: '/', //process.env.BASE_URL,
  routes
})
export default router