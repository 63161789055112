<template>
<div>
  <v-row>
    <v-col xs-12 lg-6>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>KovsieWas - Deals / Pakkette / Specials</v-toolbar-title>
      </v-toolbar>
    </v-col>
    <v-col xs-12 lg-6>
      <v-toolbar flat color="primary">
        <div v-for="grp in groupnames" :key="grp" >
        <v-btn @click="goTo(grp)" 
               :x-small="$vuetify.breakpoint.xs" 
               class="mr-1"
               >
          {{ grp }}
        </v-btn><br>      
        </div>
      </v-toolbar>
    </v-col>
    </v-row>
    <v-container fluid>
       <v-layout row wrap>
        <v-flex 
         v-for="deal in filterBy" :key="deal.package"
         flex-row
         justify-space-between
         xs-12
         >
          
         <item :packageItem="deal" />

        </v-flex>
       </v-layout>
    </v-container>

    <v-btn to="/" color="primary" class="pa-2">
      <v-icon large class="pa-2">mdi-hanger</v-icon> Go Home
    </v-btn>
</div>
</template>

<script>
import Item from '@/components/Item.vue';
import { getters } from "@/api/store";
import { zmlConfig } from '@/api/constants.js';
import { zmlFetch } from '@/api/zmlFetch.js';
  export default {
    components: {item:Item},
    //props: {packagename:String},
    data: () => ({
        getZml: getters.getState({ object: "gZml" }),
        progress:false,
        kies: '',
        groupnames: ['abc', 'def'],
        content: [],
    }),
    activated: function () {
        console.log('ACTIVATED!!!!');
    },
    computed: {
       filterBy() {
         if (this.kies == '') {
          return this.content;
         }
         // Add the data you want to search by here...
         let filterObj = {groupname: this.kies};
         // This syntax creates a new seperate copy of the data array
         let selectedData = [...this.content]
         // Loop through each property on the filter object
         for (const property in filterObj) {
           // For each property, filter the selected data to matching objects
           selectedData = selectedData.filter(o => o[property] === filterObj[property]);
         }
         // Use your filtered data
         return(selectedData)
       },
    },
    filters: {},
    methods: {
      goTo(where) {
        this.kies = where;
      },
      //This is same if (!~this.groupnames.indexOf(obj.group)) this.groupnames.push(obj.group);
      addUniq(myArray,myVal) {
        const position = myArray.indexOf(myVal);
        if (!~position) {
           myArray.push(myVal);
        }
      },
      loadDeal() {
        console.log('Load a Deal2:',this.$route.params.packagename);
        this.kies = this.$route.params.packagename;
      },
      async loadDataAgain() {
         if (this.getZml.content != '') return;
         let ts = {};
         ts.task = 'getData';
         zmlConfig.cl(ts);
         await zmlFetch(ts, this.processData);
      },
      processData(response) {
        let wasResponse = response['was'];
        let packageResponse = response['package'];
        this.getZml.packageGroup = [];
        for (let i=0; i < packageResponse.length; i++) {
          //If sortorder in package is zero, then we ignore it. (deleted)
          if (packageResponse[i].sortorder == 0) continue;
          this.getZml.package.push(packageResponse[i]);
          this.addUniq(this.getZml.packageGroup,packageResponse[i].groupname);
        }
        for (let i=0; i < wasResponse.length; i++) {
            if (wasResponse[i]['type'] == 2) {
               this.getZml.content.push(wasResponse[i]);
            }
            if (wasResponse[i]['type'] == 3) {
               this.getZml.expand.push(wasResponse[i]);
            }
            if (wasResponse[i]['type'] == 1) {
               const arrayItem = wasResponse[i]['wasid']; 
               this.getZml.screen[ arrayItem ] = wasResponse[i]['description'];
               this.getZml.screen[ wasResponse[i]['title'] ]  = wasResponse[i]['description'];
            }
        }
        zmlConfig.cl('APPscreen&content Array = ', this.getZml.screen,this.getZml.content);
      }
    },
    mounted: function () {
        zmlConfig.cl('Mount:Packages');
        if (this.getZml.content.length == 0) {
          this.loadDataAgain();
        }
        for (let i=0; i<this.getZml.package.length; i++) {
            let obj = this.getZml.package[i];
            if (obj.sortorder == 0) continue;
            this.content.push(obj);
        }
        this.groupnames = this.getZml.packageGroup;
        console.log('Unique values' , this.groupnames);
        if (this.$route.params.packagename) {
           this.kies = this.$route.params.packagename;
        }
        this.loadDeal();
         
    },
    watch: { 
       $route(to, from) {
         console.log('Hy kom nooit hier uit nie - moes "deep" ingesit het',to, from);
         this.loadDeal();
      }
    }
  }
</script>
