import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
//import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.red.darken4,
        secondary: colors.red.accent1,
        accent: colors.indigo,
        error: colors.red.lighten2,
        info: colors.info,
        success: colors.success,
        warning: colors.warning
      },
    },
  },
});

export function werner(color) {
  console.log('xxxx', color)
  Vuetify.framework.theme.themes.light.primary = colors.indigo
}

